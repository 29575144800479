// import helper
import { swissNumberInConfig } from '../../../helpers/swissNumberFormat';

/**
 * ApexChart options for the stacked bar chart type
 *
 * @param categories | array
 * @return object
 */
export function stackedBarConfig( categories ) {
  const borderColor = '#DADEE5';
  const fontFamily  = 'Montserrat, sans-serif';
  const fontSize    = '12px';
  const axisStyles  = {
    colors: '#536074',
    fontSize: fontSize,
    fontFamily: fontFamily,
    fontWeight: 400,
  };

  const axisBorder = {
    show: true,
    color: borderColor,
  };

  return {
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: ['#4E007C', '#7E3BC6', '#9462CA', '#C09DE7'],
    dataLabels: {
      formatter: value => swissNumberInConfig( value, false ),
      offsetY: 15,
      style: {
        fontSize: '14px',
        fontFamily: fontFamily,
        fontWeight: '700',
        colors: ['#fff']
      },
    },
    grid: {
      borderColor: borderColor,
      xaxis: {
        lines: {
          show: false
        }
      },
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'right',
      fontSize: fontSize,
      fontFamily: fontFamily,
      fontWeight: 400,
    },
    plotOptions: {
      bar: {
        //borderRadius: 4,
        //borderRadiusApplication: 'end',
        //borderRadiusWhenStacked: 'last',
        rangeBarGroupRows: true,
        columnWidth: '70%',
        horizontal: false,
        dataLabels: {
          position: 'top',
          total: {
            enabled: true,
            offsetY: -15,
            style: {
              fontSize: '14px',
              fontFamily: fontFamily,
              fontWeight: '700',
              colors: ['#444441']
            }
          }
        }
      },
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.01,
        }
      }
    },
    tooltip: {
      style: {
        fontSize: fontSize,
        fontFamily: fontFamily
      },
      y: {
        formatter: value => swissNumberInConfig( value, true ),
      }
    },
    xaxis: {
      axisBorder,
      type: 'category',
      categories,
      labels: {
        style: axisStyles,
        rotate: 0,
        hideOverlappingLabels: false,
      },
    },
    yaxis: {
      axisBorder,
      labels: {
        formatter: value => swissNumberInConfig( value, false ),
        style: axisStyles,
      }
    },
  }
}