import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation }              from 'react-router-dom';
import moment                       from 'moment';

// import constants
import { IDLE_STATUS }                  from '../../constants/loadingStatuses';
import { RECURRING_DONATIONS_LOCATION } from '../../constants/componentLocation';

// import actions
import { fetchRecurringDonations } from '../RecurringDonations/api/recurringDonationsSlice';

// import helpers
import getLocation               from '../../helpers/getLocation';
import { hideErrorNotification } from '../../helpers/hideErrorNotification';
import { tableDescription }      from '../../components/elements/Table/table-helper';

// import utils
import { _t, momentLocale } from '../../utils/i18n';

// import components
import ContainerWrapper  from '../../components/common/ContainerWrapper/ContainerWrapper';
import PopupConfirmation from '../../components/elements/Popup/PopupConfirmation';

export default function RecurringDonations() {
  // define dispatch
  const dispatch = useDispatch();

  // define location
  const location    = useLocation();
  const { prevUrl } = getLocation();

  // get state
  const { status, isShown, metaTitle, period } = useSelector( state => {
    const {
      recurringDonations: { status, metaTitle },
      toast: { isShown },
      calendar: { period },
    } = state;

    return { status, isShown, metaTitle, period };
  });

  // destr period
  const { dateFrom, dateTo, period: calendarPeriod } = period;

  // fetch the recurring donations data from API
  useEffect( () => { if ( status === IDLE_STATUS ) dispatch( fetchRecurringDonations() ) }, [status, dispatch] );

  // check if toast is shown and hide it
  useEffect( () => hideErrorNotification( dispatch, isShown, prevUrl ), [location] );

  // define a table period
  const tablePeriod = calendarPeriod === 'custom' ? `${ _t( 'table_from' ) } ${ moment( dateFrom ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) } - ${ moment( dateTo ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) }` : tableDescription( calendarPeriod );

  return <ContainerWrapper metaTitle={ metaTitle }
                           location={ RECURRING_DONATIONS_LOCATION }
                           popupResend
                           topStats
                           pageTitle
                           pageTitleText={ _t( 'recurring_initial_title' ) }
                           pageDescriptionText={ _t( 'recurring_initial_description' ) }
                           table
                           tableTitle={ _t( 'recurring_initial_table_title' ) }
                           tableDescription={ tablePeriod }
                           tableColumns={ 12 }
                           tableHasCalendar
                           tableModuleDescription={ _t( 'recurring_initial_table_description' ) }>
      <PopupConfirmation />
    </ContainerWrapper>
}