// import utils
import { _t } from '../utils/i18n';

// import images
import visa            from '../assets/images/visa-icon.png';
import postfinanceCard from '../assets/images/pfc-icon.png';
import postfinanceEfin from '../assets/images/pfe-icon.png';
import mastercard      from '../assets/images/mastercard-icon.png';
import twint           from '../assets/images/twint-icon.png';
import invoice         from '../assets/images/invoice-icon.png';

/**
 * Get the current payment method name by payment method id
 *
 * @param paymentMethodId | string
 * @return object
 */
export const getPaymentMethodName = paymentMethodId => {
  switch ( paymentMethodId ) {
    case 'TWI':
      return {
        name: _t( 'payment_TWI' ),
        image: twint,
      };
    case 'PAY':
      return {
        name: _t( 'payment_PAY' ),
        image: invoice,
      };
    case 'PFC':
      return {
        name: _t( 'payment_PFC' ),
        image: postfinanceCard,
      };
    case 'PEF':
      return {
        name: _t( 'payment_PEF' ),
        image: postfinanceEfin,
      };
    case 'VIS':
      return {
        name: _t( 'payment_VIS' ),
        image: visa,
      };
    case 'ECA':
      return {
        name: _t( 'payment_ECA' ),
        image: mastercard,
      };
    case 'APL':
      return {
        name: _t( 'payment_APL' ),
        image: invoice,
      };
    case 'PAP':
      return {
        name: _t( 'payment_PAP' ),
        image: invoice,
      };
    case 'AMX':
      return {
        name: _t( 'payment_AMX' ),
        image: invoice,
      };
    case 'ALP':
      return {
        name: _t( 'payment_ALP' ),
        image: invoice,
      };
    case 'AZP':
      return {
        name: _t( 'payment_AZP' ),
        image: invoice,
      };
    case 'CFY':
      return {
        name: _t( 'payment_CFY' ),
        image: invoice,
      };
    case 'KLN':
      return {
        name: _t( 'payment_KLN' ),
        image: invoice,
      };
    case 'DIB':
      return {
        name: _t( 'payment_DIB' ),
        image: invoice,
      };
    case 'PSC':
      return {
        name: _t( 'payment_PSC' ),
        image: invoice,
      };
    case 'REK':
      return {
        name: _t( 'payment_REK' ),
        image: invoice,
      };
    case 'SAM':
      return {
        name: _t( 'payment_SAM' ),
        image: invoice,
      };
    case 'ELV':
      return {
        name: _t( 'payment_ELV' ),
        image: invoice,
      };
    default:
      return {
        name: _t( 'payment_INV' ),
        image: invoice,
      };
  }
};

/**
 * Get, convert and return payment methods chart data
 *
 * @param data | array
 * @return array
 */
export const paymentMethodChartData = data => {
  return data.map( item => {
    const { x, y, color } = item;
    const { name } = getPaymentMethodName( x );

    return { x: name, y, color };
  });
};