import React           from 'react';
import { useSelector } from 'react-redux';

// import utils
import { _t } from '../../../utils/i18n';

// import icons
import { IconHexagons, IconBrandMessenger, IconCoins } from '@tabler/icons-react';

// import components
import Logo                    from '../Logo/Logo';
import SidebarToolsItem        from './SidebarToolsItem';
import SidebarToolsItemSupport from './SidebarToolsItemSupport';
import SidebarToolsItemProfile from './SidebarToolsItemProfile';

export default function SidebarTools() {
  // get the global state
  const { theme } = useSelector( state => {
    const { sidebar: { theme } } = state;

    return { theme };
  });

  // define icons color
  const iconColor = theme === 'dark' ? '#fff' : '#444441';

  return (
    <section className="sidebar-tools">
      <Logo />
      <div className="sidebar-tools__list">
        <div className="sidebar-tools__list__top">
          <SidebarToolsItem type="platform"
                            text={ _t( 'menu_title_platform' ) }>
            <IconHexagons color={ iconColor } />
          </SidebarToolsItem>

          <SidebarToolsItem type="automation"
                            text={ _t( 'menu_title_automation' ) }>
            <IconBrandMessenger color={ iconColor } />
          </SidebarToolsItem>

          <SidebarToolsItem type="dashboard"
                            text={ _t( 'menu_title_dashboard' ) }>
            <IconCoins color={ iconColor } />
          </SidebarToolsItem>
        </div>

        <div className="sidebar-tools__list__bottom">
          <SidebarToolsItemSupport />
          <SidebarToolsItemProfile />
        </div>
      </div>
    </section>
  );
}