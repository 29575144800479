import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation }              from 'react-router-dom';
import moment                       from 'moment';

// import constants
import { IDLE_STATUS }                           from '../../constants/loadingStatuses';
import { RECURRING_DONATIONS_INTERVAL_LOCATION } from '../../constants/componentLocation';

// import actions
import { fetchRecurringDonationsInterval } from '../RecurringDonationsInterval/api/recurringDonationsIntervalSlice';

// import helpers
import getLocation               from '../../helpers/getLocation';
import { hideErrorNotification } from '../../helpers/hideErrorNotification';
import { tableDescription }      from '../../components/elements/Table/table-helper';

// import utils
import { _t, momentLocale } from '../../utils/i18n';
import { getUser }          from '../../utils/Auth';

// import components
import ContainerWrapper from '../../components/common/ContainerWrapper/ContainerWrapper';
import ModuleWrapper    from '../../components/common/ModuleWrapper/ModuleWrapper';
import Calendar         from '../../components/elements/Calendar/Calendar';
import ChartBarStacked  from '../../components/elements/ChartBarStacked';

export default function RecurringDonations() {
  // define dispatch
  const dispatch = useDispatch();

  // define location
  const location    = useLocation();
  const { prevUrl } = getLocation();

  // get state
  const { status, isShown, metaTitle, isRemembered, period } = useSelector( state => {
    const {
      recurringDonationsInterval: { status, metaTitle },
      toast: { isShown },
      login: { isRemembered },
      calendar: { period },
    } = state;

    return { status, isShown, metaTitle, isRemembered, period };
  });

  // destr period
  const { dateFrom, dateTo, period: calendarPeriod } = period;

  // get the user currency
  const { currency } = getUser( isRemembered );

  // fetch the recurring donations data from API
  useEffect( () => { if ( status === IDLE_STATUS ) dispatch( fetchRecurringDonationsInterval() ) }, [status, dispatch] );

  // check if toast is shown and hide it
  useEffect( () => hideErrorNotification( dispatch, isShown, prevUrl ), [location] );

  // define a table period
  const tablePeriod = calendarPeriod === 'custom' ? `${ _t( 'table_from' ) } ${ moment( dateFrom ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) } - ${ moment( dateTo ).locale( momentLocale() ).format( 'DD. MMMM, YYYY' ) }` : tableDescription( calendarPeriod );

  return <ContainerWrapper metaTitle={ metaTitle }
                           location={ RECURRING_DONATIONS_INTERVAL_LOCATION }
                           popupResend
                           topStats
                           pageTitle
                           pageTitleText={ _t( 'recurring_interval_title' ) }
                           pageDescriptionText={ _t( 'recurring_interval_description' ) }
                           table
                           tableTitle={ _t( 'recurring_interval_table_title' ) }
                           tableDescription={ tablePeriod }
                           tableColumns={ 10 }
                           tableModuleDescription={ _t( 'recurring_interval_table_title' ) }
                           childrenPosition="top">
      <div className="row">
        <div className="col col-xs-12">
          <ModuleWrapper title={ _t( 'recurring_interval_forecast_title' ) }
                         description={ `in ${ currency }` }>
            <Calendar location={ RECURRING_DONATIONS_INTERVAL_LOCATION } />
            <ChartBarStacked />
          </ModuleWrapper>
        </div>
      </div>
    </ContainerWrapper>
}