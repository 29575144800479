import React, { useState }          from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import constants
import { LOADING_STATUS } from '../../../constants/loadingStatuses';

// import helpers
import { emailValidator } from '../../../helpers/emailValidator';

// import utils
import { _t_byBrowserLang } from '../../../utils/i18n';

// import actions
import { changeStatus, processingForgot } from './store/loginSlice';

// import components
import ButtonPreloader from '../../elements/ButtonPreloader/ButtonPreloader';

export default function LoginForgot() {
  // define dispatch
  const dispatch = useDispatch();

  // define the global state
  const { status } = useSelector( state => state.login );

  const [email, setEmail]           = useState( '' );
  const [isEmailErr, setIsEmailErr] = useState( false );

  /**
   * Processing the submitting function
   *
   * @param e | Event
   */
  const handleSubmit = e => {
    e.preventDefault();

    if ( !emailValidator( email ) ) {
      // add an error state to the email field
      setIsEmailErr( true );
    } else {
      dispatch( changeStatus( LOADING_STATUS ) );
      dispatch( processingForgot() )
    }
  };

  return (
    <div className="login__area--forgot">
      <h1 className="login__title">{ _t_byBrowserLang( 'forgot_title' ) }</h1>
      <p className="login__description">{ _t_byBrowserLang( 'forgot_subtitle' ) }</p>
      <form className="login__form"
            onSubmit={ ( e ) => handleSubmit( e ) }>
        <div className="login__form__field-group">
          <input className={ isEmailErr ? 'login__form__field login__form__field--is-error' : 'login__form__field' }
                 id="login-email"
                 type="email"
                 onClick={ () => setIsEmailErr( false ) }
                 onChange={ ( e ) => setEmail( e.target.value ) }
                 placeholder=" "/>
          <label className="login__form__label"
                 htmlFor="login-email">{ _t_byBrowserLang( 'forgot_email_label' ) }</label>
        </div>

        <button className={ status === LOADING_STATUS ? 'login__form__submit login__form__submit--is-loading' : 'login__form__submit' }
                type="submit"
                disabled={ status === LOADING_STATUS }>
          { status === LOADING_STATUS ? <ButtonPreloader /> : _t_byBrowserLang( 'forgot_button_text' ) }
        </button>
      </form>
    </div>
  )
}