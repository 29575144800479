// import constants
import { API_URL } from '../constants/base';
import { LOGIN_LOGOUT, LOGIN_UPDATE_TOKENS, TOAST_SHOW } from '../constants/dispatchTypes';
import {
  TODAY_LABEL, YESTERDAY_LABEL, LAST_7_DAYS_LABEL, LAST_30_DAYS_LABEL, LAST_MONTH_LABEL, THIS_QUARTER_LABEL,
  LAST_QUARTER_LABEL, THIS_MONTH_LABEL, THIS_YEAR_LABEL, LAST_YEAR_LABEL, CALENDAR_TODAY, CALENDAR_YESTERDAY,
  CALENDAR_LAST_7_DAYS, CALENDAR_LAST_30_DAYS, CALENDAR_THIS_MONTH, CALENDAR_LAST_MONTH, CALENDAR_THIS_QUARTER,
  CALENDAR_LAST_QUARTER, CALENDAR_THIS_YEAR, CALENDAR_LAST_YEAR
} from '../constants/calendarRanges';

// import client
import { client } from './client';

// import utils
import { getAccessToken, getRefreshToken, isUserRemembered } from '../utils/Auth';
import { _t } from '../utils/i18n/';

/**
 * Convert the Calendar period to the API period
 *
 * @param period | string
 * @return string
 */
export function getPeriod( period ) {
  switch( period ) {
    case TODAY_LABEL:
      return CALENDAR_TODAY;
    case YESTERDAY_LABEL:
      return CALENDAR_YESTERDAY;
    case LAST_7_DAYS_LABEL:
      return CALENDAR_LAST_7_DAYS;
    case LAST_30_DAYS_LABEL:
      return CALENDAR_LAST_30_DAYS;
    case THIS_MONTH_LABEL:
      return CALENDAR_THIS_MONTH;
    case LAST_MONTH_LABEL:
      return CALENDAR_LAST_MONTH;
    case THIS_QUARTER_LABEL:
      return CALENDAR_THIS_QUARTER;
    case LAST_QUARTER_LABEL:
      return CALENDAR_LAST_QUARTER;
    case THIS_YEAR_LABEL:
      return CALENDAR_THIS_YEAR;
    case LAST_YEAR_LABEL:
      return CALENDAR_LAST_YEAR;
    default:
      return 'custom';
  }
}

/**
 * Refresh token request
 *
 * @param type | string
 * @param isRemembered | bool
 * @param dispatch | function
 */
export async function refreshTokenHelper( type = 'initial', isRemembered, dispatch ) {
  if ( getRefreshToken( isRemembered ) === undefined ) {
    dispatch.dispatch( { type: LOGIN_LOGOUT } );
    window.location.reload();
  } else {
    try {
      await client.post( API_URL + 'authentication/refresh', { refresh: getRefreshToken( isRemembered ) } )
        .then( res => {
          const { access, refresh } = res.data;
          const data = {
            type: LOGIN_UPDATE_TOKENS,
            payload: { access, refresh, isRemembered }
          };

          type === 'initial' ? dispatch.dispatch( data ) : dispatch( data );
        });
    } catch ( err ) {
      dispatch.dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
      console.log( err );

      return Promise.reject( err );
    }
  }
}

/**
 * Request initial data if the page loads first time
 *
 * @param args
 * @param thunkAPI
 * @param data | object
 * @param secondParam | bool
 * @return object
 */
export async function initialRequest( args, thunkAPI, data, secondParam = false ) {
  try {
    const { period, dateFrom, dateTo } = thunkAPI.getState().calendar.period;
    const { defaultUrl, updateUrl }    = data;
    const isRemembered                 = isUserRemembered();

    // refresh an access token
    await refreshTokenHelper( 'initial', isRemembered, thunkAPI );

    // define auth header
    const headers = { 'Authorization': "Bearer " + getAccessToken( isRemembered ) };

    if ( period === '' || period === 'default' ) {
      const response = await client.get( API_URL + defaultUrl, { headers } );

      return response.data;
    } else {
      const sign = secondParam ? '&' : '?';

      const response = await client.get(
        period === 'custom' ? API_URL + updateUrl + sign + 'dateFrom=' + dateFrom + '&dateTo=' + dateTo : API_URL + updateUrl + sign + 'period=' + period,
        { headers }
      );

      return response.data;
    }
  } catch ( err ) {
    thunkAPI.dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
    console.log( err );

    return Promise.reject( err );
  }
}

/**
 * Make request to update the data if the date range was changed
 *
 * @param dateRange | object
 * @param data | object
 * @param secondParam | bool
 */
export const updateDateRequest = ( dateRange, data, secondParam = false ) => {
  const { period: periodType, dateFrom, dateTo } = dateRange;
  const { defaultUrl, updateUrl, dispatchType } = data;
  const period = getPeriod( periodType );
  const sign   = secondParam ? '&' : '?';
  let url = '';

  if ( period === 'default' ) {
    url = API_URL + defaultUrl;
  } else if ( period === 'custom' ) {
    url = API_URL + updateUrl + sign + 'dateFrom=' + dateFrom + '&dateTo=' + dateTo;
  } else {
    url = API_URL + updateUrl + sign + 'period=' + period;
  }

  return async function thunk( dispatch ) {
    try {
      const isRemembered = isUserRemembered();

      await refreshTokenHelper( 'update', isRemembered, dispatch );

      const response = await client.get( url, { headers: { 'Authorization': "Bearer " + getAccessToken( isRemembered ) } } );

      dispatch( { type: dispatchType, payload: response.data } );
    } catch ( err ) {
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
      console.log( err );

      return Promise.reject( err );
    }
  }
};